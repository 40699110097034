import { useState, useContext, useEffect } from "react";
import UserContext from "../../../Context/UserContext";
import ClientContext from "../../../Context/ClientContext";
import ParametroContext from "../../../Context/ParametrosContext";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { BiSearchAlt } from 'react-icons/bi'
import { format } from 'date-fns';
import { EChart } from '@kbox-labs/react-echarts'
import axios from "axios";
import { BarLoader } from "react-spinners"
import YearFilter from "../../shared/YearFilter";


export default function TableroPrincipal() {
  const API_URL = 'https://api.medicionanalytic.com/api'
  const { logData } = useContext(ClientContext)
  const { userData } = useContext(UserContext)
  const { paramData } = useContext(ParametroContext);

  let Zeries = []
  let Puntoz = []

  const [DataNPS, setDNPS] = useState([])
  const [MensualNPS, setMNPS] = useState([])
  const [AttrMens, setAtrr] = useState([])
  const [EvoPnts, setEPT] = useState([])
  const [reportes, setRep] = useState([])
  const [enc, setE] = useState()

  const [sPeriods, setSP] = useState([])
  const [sPuntos, setSPtos] = useState([])
  const [segmentos, setSegmentos] = useState([])
  const [cities, setCities] = useState([])
  const [joints, setJoints] = useState([])


  const [selectedYear, setSelectedYear] = useState(null); //constante para un año seleccionado
  const [periodos, setPeriodos] = useState([])
  const [encuestas, setEncuestas] = useState([])
  const [Basencuestas, setBaseEncuestas] = useState([])
  const [puntos, setPuntos] = useState([])
  const [Sucursales, setSuc] = useState([]) //filtro de sucursales.

  const [preguntas, setPreg] = useState([])
  const [respuestas, setR] = useState([])
  const [PS, setPS] = useState([])
  const [Reps, setReps] = useState([])
  const [Ptz, setPoints] = useState([])

  const [loader, setLoad] = useState(false)

  //Graph config      
  const config = {
    rotate: 0,
    align: 'center',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
    onChange: function () {
      const labelOption = {
        rotate: config.rotate,
        align: config.align,
        verticalAlign: config.verticalAlign,
        position: config.position,
        distance: config.distance
      };
    }
  };
  const labelOption = {
    show: true,
    position: config.position,
    distance: config.distance,
    align: config.align,
    verticalAlign: config.verticalAlign,
    rotate: config.rotate,
    formatter: '{c}',
    fontSize: 16,
  };
  const labelOption2 = {
    show: true,
    position: config.position,
    distance: config.distance,
    align: config.align,
    verticalAlign: config.verticalAlign,
    rotate: config.rotate,
    formatter: '{c}' + '%',
    fontSize: 16,
  };

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    axios.get(API_URL + '/joint', { params: { client_id: Number(logData.id) } }).then(res => { setJoints(res.data) })
    axios.get(API_URL + '/pregunta/all', { params: { client_id: logData.id } }).then((res) => { setPreg(res.data) })
    axios.get(API_URL + '/ciudad', { params: { client_id: logData.id } }).then((response) => { response.data.forEach(p => { p.label = p.nombre_ciudad; p.value = p.id; }); setCities(response.data); });
    axios.get(API_URL + '/segmento', { params: { client_id: logData.id, tipo: "pregunta" } }).then((response) => {
      let temp = response.data
      temp.forEach(el => { el.label = el.nombre_segmento; el.value = el.id; })
      setSegmentos(temp.filter(t => { return t.aplica_calculo === 1 }))
    })
    axios.get(API_URL + '/sucursal', { params: { cliente_id: logData.id } }).then((res) => {
      res.data.forEach(p => { p.label = p.nombre_sucursal; p.value = p.id; })
      if (logData.id === 49) { setPuntos(res.data) }
      else { setSuc(res.data) }
    })
    axios.get(API_URL + '/encuesta/all', { params: { client_id: logData.id } }).then((response) => {
      let n;
      let temp;
      if (typeof response.data === 'object') { temp = Object.keys(response.data).map((key) => response.data[key]); }
      else { temp = response.data }
      if (userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null); }
      else { let vat = temp.filter(el => { return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); n = vat.filter(encuesta => encuesta.punto_encuesta !== null); }
      setBaseEncuestas(n)
    })
  }, [])


  // Carga de periodos basados en `selectedYear`
  useEffect(() => {
    if (!selectedYear) return;

    axios.get(`${API_URL}/periodo`, { params: { client_id: logData.id, year: selectedYear } })
      .then((response) => {
        const periodos = response.data
          .map((p) => ({
            ...p,
            label: p.nombre_periodo,
            value: p.id,
          }))
          .sort((a, b) => new Date(a.periodo_inicio) - new Date(b.periodo_inicio));
        setPeriodos(periodos);
      })
      .catch((error) => console.error("Error al cargar periodos:", error));
  }, [selectedYear, logData.id]); // Se ejecuta cuando `selectedYear` cambia



  function Tabulada(res) {
    let sum = []
    res.forEach((r) => {
      switch (r.tipo_pregunta) {
        case 'escala-tri':
          if (r.respuesta === 'malo') { r.Value = 0; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r) }
          if (r.respuesta === 'regular') { r.Value = 50; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r) }
          if (r.respuesta === 'excelente') { r.Value = 100; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r) }
          break;
        case 'nps': case 'npsna':
          if (r.respuesta !== "na") {
            r.Value = (r.respuesta * 100) / 10; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : "";
            sum.push(r);
          }
          break;
        case 'escala':
          if (r.respuesta !== "na") {
            r.Value = (r.respuesta * 100) / r.valor_max; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : "";
            sum.push(r);
          }
          break;
        case 'smiles': case 'stars':
          r.Value = (r.respuesta * 100) / 5; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : "";
          sum.push(r);
          break;
        case 'thumbs': case 'audit':
          if (r.respuesta === "si") { r.Value = 100; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r) }
          else if (r.respuesta === "no") { r.Value = 0; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r) }
          else { r.Value = "na"; r.Segmento = preguntas.find(p => p.id === r.pregunta_id) ? preguntas.find(p => p.id === r.pregunta_id).segmento : ""; sum.push(r) }
          break;
      }
    })
    return sum
  }

  function showData(temp, reps) {
    let MNPS = []
    let DNPS = []
    let AM = []
    let PTZ = []
    let filtered = Tabulada(temp) //en filtered está el texto            
    let x = filtered.filter(t => (t.Value >= 90 || t.Value <= 60) && t.tipo_pregunta === "nps")
    let y = filtered.filter(t => (t.Value < 90 && t.Value > 60) && t.tipo_pregunta === "nps")
    let totalength

    PS.forEach((Per) => {
      totalength = x.filter(p => { return Number(p.periodo_id) === Number(Per.id) }).length + y.filter(p => { return Number(p.periodo_id) === Number(Per.id) }).length
      MNPS.push({
        y: (((x.filter(p => (p.periodo_id === Per.id && p.Value >= 90)).length * 100) / totalength) - ((x.filter(p => p.periodo_id === Per.id && p.Value <= 60).length * 100) / totalength)).toFixed(2),
        x: Per.nombre_corto
      })
      DNPS.push({
        y: ["0",
          ((x.filter(p => p.periodo_id === Per.id && p.Value >= 90).length * 100) / totalength).toFixed(2),
          ((y.filter(p => p.periodo_id === Per.id).length * 100) / totalength).toFixed(2),
          ((x.filter(p => p.periodo_id === Per.id && p.Value <= 60).length * 100) / totalength).toFixed(2),
        ],
        x: Per.nombre_corto
      })
      segmentos.filter(s => s.encuesta_id === enc.id).forEach(s => {
        let tot = filtered.filter(x => (Number(x.Segmento) === Number(s.id) && x.periodo_id === Per.id && x.tipo_pregunta !== "texto" && x.tipo_pregunta !== "nps"))
        let xS = tot.filter(t => (t.Value >= 90)).length
        let Val = (xS * 100 / tot.length).toFixed(2)
        AM.push({ y: Val, Segmento: s.nombre_segmento, segmento_id: s.id, periodo_id: Per.id, x: Per.nombre_corto })
      })
      Ptz.forEach(p => {
        let tot = filtered.filter(x => (x.punto_id === p.id && x.periodo_id === Per.id && x.tipo_pregunta === "nps"))
        let xS = tot.filter(t => (t.Value >= 90)).length
        let xs = tot.filter(t => (t.Value <= 60)).length
        let Val = (((xS - xs) * 100) / tot.length).toFixed(2)
        PTZ.push({ y: Val, punto: p.nombre_sucursal, punto_id: p.id, periodo_id: Per.id, x: Per.nombre_corto })
      })
    })
    setReps(reps)
    setMNPS(MNPS); setDNPS(DNPS); setAtrr(AM); setEPT(PTZ);
  }

  const SetFilters = (data, e) => {
    e.preventDefault();
    setMNPS([]); setDNPS([]); setAtrr([]); setEPT();
    if (!enc) { return; }
    let temp = []
    let values = getValues();
    let tab = respuestas
    let repo = reportes.filter(d => respuestas.find(r => r.reporte_id === d.id))
    let init = new Date(values.date_init.replace(/-/g, '\/').replace(/T.+/, ''))
    let end = new Date(values.date_fin.replace(/-/g, '\/').replace(/T.+/, ''))
    let reps = repo.filter(r => {
      let created = new Date(format(r.created_at, 'yyyy/MM/dd').replace(/-/g, '\/').replace(/T.+/, ''))
      if ((created.getTime() <= end.getTime()) && (created.getTime() >= init.getTime())) { return sPuntos.some(p => { return sPeriods.some(f => { return p.id === r.sucursal && f.id === r.periodo_id }) }) }
    })
    let repos = repo.filter(el => { return sPuntos.some(p => { return sPeriods.some(f => { return p.id === el.sucursal && f.id === el.periodo_id }) }) })
    sPeriods.sort(function (a, b) { return new Date(a.periodo_inicio) - new Date(b.periodo_inicio); });
    if (values.date_fin && values.date_init) {
      temp = tab.filter(el => {
        return sPuntos.some(p => {
          return sPeriods.some(f => {
            return reps.some(rep => {
              return p.id === el.punto_id && f.id === el.periodo_id && el.encuesta_id === enc.id && el.reporte_id === rep.id
            })
          })
        })
      })
    }
    else {
      temp = tab.filter(el => {
        return sPuntos.some(p => {
          return sPeriods.some(f => {
            return p.id === el.punto_id && f.id === el.periodo_id && el.encuesta_id === enc.id
          })
        })
      })
    }
    setPS(sPeriods);
    setPoints(sPuntos);
    if (values.date_fin && values.date_init) { showData(temp, reps); }
    else { showData(temp, repos); }
  }

  const handleEncuesta = (e) => {
    setLoad(true)
    e.preventDefault()
    let enc = encuestas.find(el => el.id === Number(e.target.value))
    if (enc) {
      axios.get(API_URL + '/respuesta', { params: { encuesta_id: Number(e.target.value), periodo_id: sPeriods } }).then(res => { setR(res.data); setLoad(false) })
      axios.get(API_URL + '/reporte', { params: { encuesta: Number(e.target.value) } }).then(res => { setRep(res.data); setE(enc); })
    }
  }
  const getColors = (val) => {
    let x = Number(val)
    if (x < paramData.values.amarillo) { return "lightcoral" }
    if (x < paramData.values.verde) { return "yellow" }
    if (x >= paramData.values.verde) { return "yellowgreen" }
  }
  useEffect(() => {
    setEncuestas([])
    if (sPeriods.length > 0) {
      let temp = joints.filter(j => { return sPeriods.some(p => { return Number(p.id) === Number(j.periodo_id) }) })
      let temp2 = Basencuestas.filter(e => { return temp.some(j => { return (Number(j.encuesta_id) === Number(e.id) && e.tipo_encuesta === "encuesta") }) })
      setEncuestas(temp2)
    }
  }, [sPeriods])

  const handleCity = (e) => {
    e.preventDefault()
    setSPtos([])
    let sucursales = puntos.filter(s => { return Number(s.ciudad_id) === Number(e.target.value) })
    setSuc(sucursales)
  }

  if (enc) {
    Zeries = segmentos.filter(s => { return AttrMens.some(a => { return s.encuesta_id === enc.id && s.id === a.segmento_id && Number(a.y) > 0 }) })
      .map(s => {
        return {
          data: AttrMens.filter(a => a.segmento_id === s.id).map(d => { return (d.y) }),
          type: 'bar', barGap: 0, name: s.nombre_segmento, label: labelOption,
        }
      })
    Puntoz = PS.map(p => {
      return {
        data: EvoPnts.filter(a => a.periodo_id === p.id).map(d => { return { value: Number(d.y), itemStyle: PS.length > 1 ? {} : { color: getColors(d.y) } } }),
        type: 'bar', barGap: 0, name: p.nombre_corto, label: labelOption,
      }
    })
    console.log(Puntoz)
    console.log(EvoPnts)
  }

  return (<>
    <div className="content-container">
      <Form onSubmit={handleSubmit(SetFilters)}>
        <Row className="mb-1">
          <Col>
            {/* Selector de año */}
            <YearFilter selectedYear={selectedYear} setSelectedYear={setSelectedYear} />
          </Col>
          <Col>
            <MultiSelect className="multi-selecto"
              overrideStrings={{
                "allItemsAreSelected": "Todos los Periodos Seleccionados.",
                "clearSearch": "Limpiar busqueda",
                "clearSelected": "Limpiar Seleccion",
                "noOptions": "Sin opciones",
                "search": "Buscar",
                "selectAll": "Todos",
                "selectAllFiltered": "Todos (Filtrado)",
                "selectSomeItems": "Seleccionar Periodo",
                "create": "Create",
              }
              }
              options={periodos}
              value={sPeriods}
              onChange={setSP}
              labelledBy="Select"
            />
          </Col>

        </Row>
        <Row>
          <Col>
            <Form.Select onChange={handleEncuesta} className="multi-selecto" >
              <option value={null}>Sin Encuesta Seleccionada</option>
              {encuestas.length > 0 ? encuestas.map(enc => { return <option value={enc.id}>{enc.nombre_encuesta}</option> }) : null}
            </Form.Select>
          </Col>
          {logData.id === 49 ?
            <Col>
              <Form.Select onChange={handleCity} >
                <option value={null}>Sin Ciudad Seleccionada</option>
                {cities.length > 0 ? cities.map(enc => { return <option value={enc.id}>{enc.nombre_ciudad}</option> }) : null}
              </Form.Select>
            </Col>
            : null}
          <Col>
            <MultiSelect className="multi-selecto"
              overrideStrings={{
                "allItemsAreSelected": "Todos los Puntos Seleccionados.",
                "clearSearch": "Limpiar busqueda",
                "clearSelected": "Limpiar Seleccion",
                "noOptions": "Sin opciones",
                "search": "Buscar",
                "selectAll": "Todos",
                "selectAllFiltered": "Todos (Filtrado)",
                "selectSomeItems": "Seleccionar Puntos",
                "create": "Create",
              }
              }
              options={userData.permiso === "admin" ?
                Sucursales :
                Sucursales.filter(el => { return userData.puntos.split(",").indexOf(el.id.toString()) > -1 })}
              value={sPuntos}
              onChange={setSPtos}
              labelledBy="Select"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className='m-2 mt-1 mb-1'>Fecha inicio</Form.Label>
            <Form.Control className='rmsc' type='date' {...register("date_init")} />
          </Col>
          <Col>
            <Form.Label className='m-2 mt-1 mb-1'>Fecha fin</Form.Label>
            <Form.Control className='rmsc' type='date' {...register("date_fin")} />
          </Col>
          <Col></Col>
        </Row>
        <Row className="m-1 mt-3">
          <Button type="submit" className="App-button m-2 mt-0" disabled={loader}>
            {loader ? <BarLoader className="m-2" color="#FFF" width={100} /> : <>Aplicar filtros <BiSearchAlt /></>}
          </Button>
        </Row>
      </Form>
      {//consulta ?
        <div className="mb-4">
          <Row className="row-excel-button">
            <h3 className="p-2" style={{ width: '50vw' }}>Tablero Principal</h3>
          </Row>
          <Row>
            <Col className="collumn-alt bkg-white m-1">
              <EChart className="Echart-2" renderer={'canvas'} tooltip={{ trigger: "item" }} legend={{ bottom: '15%', left: 'center' }}
                title={{ text: 'Evolucion NPS' }}
                notMerge
                toolbox={{ show: true, feature: { saveAsImage: {} } }}
                xAxis={{
                  type: 'category',
                  data: MensualNPS.map(m => { return m.x })
                }}
                yAxis={{ type: 'value' }}
                series={[
                  {
                    data: MensualNPS.map(m => { return m.y }),
                    type: 'line',
                    smooth: true,
                    label: labelOption2,
                  }
                ]} />
            </Col>
            <Col className="collumn-alt bkg-white m-1">
              <EChart className="Echart" renderer={'canvas'} tooltip={{ trigger: "item" }} legend={{ bottom: '0%', left: 'center' }}
                title={{ text: 'Detalle NPS' }}
                xAxis={{ type: 'category', data: DataNPS.map(d => { return d.x }), axisLabel: { interval: 0 } }}
                yAxis={{ type: 'value' }}
                toolbox={{ show: true, feature: { saveAsImage: {} } }}
                series={[
                  { data: DataNPS.map(d => { return d.y[0] }), type: 'bar', stack: 'a' },
                  { data: DataNPS.map(d => { return (d.y[1]) }), type: 'bar', stack: 'a', name: 'promotor', label: labelOption2 },
                  { data: DataNPS.map(d => { return (d.y[2]) }), type: 'bar', stack: 'a', name: 'neutro', label: labelOption2 },
                  { data: DataNPS.map(d => { return (d.y[3]) }), type: 'bar', stack: 'a', name: 'detractor', label: labelOption2 }
                ]} />
            </Col>
          </Row>
          <Row>
            <Col className="collumn-alt bkg-white m-1">
              <EChart className="Echart" renderer={'canvas'} tooltip={{ trigger: "item" }}
                title={{ text: 'Nro de Encuestas' }}
                toolbox={{ show: true, feature: { saveAsImage: {} } }}
                xAxis={{
                  type: 'category',
                  axisTick: { show: false },
                  data: PS.map(p => p.nombre_corto),
                  axisLabel: { interval: 0 }
                }}
                yAxis={{ type: 'value' }}
                series={[
                  {
                    data: PS.map(p => { return Reps.filter(r => r.periodo_id === p.id).length }),
                    type: 'bar',
                    label: labelOption,
                  }
                ]} />
            </Col>
            <Col className="collumn-alt bkg-white m-1">
              <EChart className={segmentos.filter(s => { return AttrMens.some(a => { return s.encuesta_id === enc.id && s.id === a.segmento_id && Number(a.y) > 0 }) }).length > 10 ? "Echart-wide" : "Echart"} renderer={'canvas'} tooltip={{ trigger: "item" }}
                legend={{ bottom: '0%', left: 'center', itemGap: 5 }}
                title={{ text: 'Detalle de atributos' }}
                toolbox={{ show: true, feature: { saveAsImage: {} } }}
                xAxis={{ type: 'category', data: PS.map(d => { return d.nombre_corto }), axisLabel: { interval: 0 } }}
                yAxis={{ type: 'value' }}
                series={Zeries}
              />
            </Col>
          </Row>
          <Row>
            <Col className="collumn-alt bkg-white m-1">
              <EChart className="Echart-wide" renderer={'canvas'} tooltip={{ trigger: "item" }} legend={{ bottom: '0%', left: 'center' }}
                title={{ text: 'Evolucion de sucursales' }}
                toolbox={{ show: true, feature: { saveAsImage: {} } }}
                xAxis={{ type: 'category', data: sPuntos.map(d => { return d.nombre_sucursal }), axisLabel: { interval: 0 } }}
                yAxis={{ type: 'value' }}
                series={Puntoz} />
            </Col>
          </Row>
        </div> /*: null*/}
    </div>
  </>)
}