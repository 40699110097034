import { useState, useContext, useEffect } from 'react'
import { Form, Button, Table, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { BiSearchAlt } from 'react-icons/bi'
import { BarLoader } from 'react-spinners';
import ClientContext from '../../../Context/ClientContext';
import UserContext from '../../../Context/UserContext';
import axios from 'axios';
import YearFilter from '../../shared/YearFilter';

export default function Statistics() {

    const API_URL = 'https://api.medicionanalytic.com/api'
    const { logData } = useContext(ClientContext)
    const { userData } = useContext(UserContext)

    const [sPeriods, setSP] = useState([])
    const [sPuntos, setSPtos] = useState([])
    const [sSecciones, setSS] = useState([])

    const [userList, setList] = useState([])
    const [cities, setCities] = useState([])
    const [Secciones, setS] = useState([])
    const [Sucursales, setSuc] = useState([]) //filtro de sucursales.
    const [enc, setE] = useState({})
    const [loader, setLoad] = useState(false)


    const [selectedYear, setSelectedYear] = useState(null); //constante para un año seleccionado
    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])
    const [secciones, setSecciones] = useState([])
    const [puntos, setPuntos] = useState([])
    const [reportes, setRep] = useState([])

    const [consulta, setConsulta] = useState(false)
    const [base, setBase] = useState([])

    const [FP, setFP] = useState([])
    const [PS, setPS] = useState([])
    const [DF, setDF] = useState([])
    const [DE, setDE] = useState([])
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "onBlur" });

    useEffect(() => {
        axios.get(API_URL + '/usuario', { params: { cliente_id: logData.id } }).then(res => { setList(res.data) })
        axios.get(API_URL + '/ciudad', { params: { client_id: logData.id } }).then((response) => { response.data.forEach(p => { p.label = p.nombre_ciudad; p.value = p.id; }); setCities(response.data); });
        /*         axios.get(API_URL + '/periodo', { params: { client_id: logData.id } }).then((response) => {
                    response.data.forEach(p => {
                        p.label = p.nombre_periodo;
                        p.value = p.id;
                    })
                    response.data.sort(function (a, b) { return new Date(a.periodo_inicio) - new Date(b.periodo_inicio); });
                    setPeriodos(response.data)
                }) */
        axios.get(API_URL + '/sucursal', { params: { cliente_id: logData.id } }).then((res) => {
            res.data.forEach(p => { p.label = p.nombre_sucursal; p.value = p.id; })
            if (logData.id === 49) { setPuntos(res.data) }
            else { setSuc(res.data) }
        })
        axios.get(API_URL + '/seccion/report', { params: { client_id: logData.id } }).then((response) => {
            response.data.forEach(p => { p.label = p.nombre_seccion; p.value = p.id; })
            setSecciones(response.data)
        })
        axios.get(API_URL + '/encuesta/all', { params: { client_id: logData.id } }).then((response) => {
            let n;
            let temp;
            if (typeof response.data === 'object') { temp = Object.keys(response.data).map((key) => response.data[key]); }
            else { temp = response.data }
            if (userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null); }
            else { let vat = temp.filter(el => { return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); n = vat.filter(encuesta => encuesta.punto_encuesta !== null); }
            setEncuestas(n)
        })
    }, [])

    // Carga de periodos basados en `selectedYear`
    useEffect(() => {
        if (!selectedYear) return;

        axios.get(`${API_URL}/periodo`, { params: { client_id: logData.id, year: selectedYear } })
            .then((response) => {
                const periodos = response.data
                    .map((p) => ({
                        ...p,
                        label: p.nombre_periodo,
                        value: p.id,
                    }))
                    .sort((a, b) => new Date(a.periodo_inicio) - new Date(b.periodo_inicio));
                setPeriodos(periodos);
            })
            .catch((error) => console.error("Error al cargar periodos:", error));
    }, [selectedYear, logData.id]); // Se ejecuta cuando `selectedYear` cambia


    const handleEncuesta = (e) => {
        setLoad(true)
        e.preventDefault()
        setSS([])
        let sec = []
        if (userData.permiso === "admin") { sec = secciones }
        else {
            sec = secciones.filter(el => { return userData.secciones.split(",").indexOf(el.id.toString()) > -1 })
        }

        let enc = encuestas.find(el => el.id === Number(e.target.value))
        let tem = sec.filter(s => { return s.encuesta_id === Number(e.target.value) })
        if (enc) {
            axios.get(API_URL + '/respuesta', { params: { encuesta_id: Number(e.target.value), periodo_id: sPeriods } }).then(res => { setBase(res.data); setLoad(false) })
            axios.get(API_URL + '/reporte', { params: { encuesta: Number(e.target.value) } }).then(res => { setRep(res.data) })

            setE(enc)
            setS(tem)
        }
    }
    const SetFilters = (data, e) => {
        e.preventDefault();
        let temp = []
        let repo = reportes.filter(d => base.find(r => r.reporte_id === d.id))
        temp = repo.filter(el => {
            return sPuntos.some(p => {
                return sPeriods.some(f => {
                    return p.id === el.sucursal && f.id === el.periodo_id
                })
            })
        })
        if (temp.length >= 0) {
            setPS(sPeriods);
            setFP(sPuntos);
            showData(temp)
        }
        else { setConsulta(false) }
    }

    const showData = (data) => {
        console.log(data)
        setDF(data)
        setConsulta(true)
    }

    const handleCity = (e) => {
        e.preventDefault()
        setSPtos([])
        let sucursales = puntos.filter(s => { return Number(s.ciudad_id) === Number(e.target.value) })
        setSuc(sucursales)
    }

    return (
        <div className="content-container">
            <Form onSubmit={handleSubmit(SetFilters)}>
                <Row className="mb-1">
                    <Col>
                        {/* Selector de año */}
                        <YearFilter selectedYear={selectedYear} setSelectedYear={setSelectedYear} />
                    </Col>
                    <Col>
                        <MultiSelect
                            overrideStrings={{
                                "allItemsAreSelected": "Todo Seleccionado.",
                                "clearSearch": "Limpiar busqueda",
                                "clearSelected": "Limpiar Seleccion",
                                "noOptions": "Sin opciones",
                                "search": "Buscar",
                                "selectAll": "Todos",
                                "selectAllFiltered": "Todos (Filtrado)",
                                "selectSomeItems": "Seleccionar Periodo",
                                "create": "Create",
                            }
                            }
                            options={periodos}
                            value={sPeriods}
                            onChange={setSP}
                            labelledBy="Select"
                        />
                    </Col>
                </Row>
                {/*verificar que filtros son necesarios y cuales no*/}
                <Row className='mb-1'>
                    <Col>
                        <Form.Select onChange={handleEncuesta} >
                            <option value={null}>Sin Encuesta Seleccionada</option>
                            {encuestas.length > 0 ? encuestas.map(enc => { return <option value={enc.id}>{enc.nombre_encuesta}</option> }) : null}
                        </Form.Select>
                    </Col>
                    <Col>
                        {logData.id === 49 ?
                            <Form.Select onChange={handleCity} >
                                <option value={null}>Sin Ciudad Seleccionada</option>
                                {cities.length > 0 ? cities.map(enc => { return <option value={enc.id}>{enc.nombre_ciudad}</option> }) : null}
                            </Form.Select>
                            : <MultiSelect
                                overrideStrings={{
                                    "allItemsAreSelected": "Todo Seleccionado.",
                                    "clearSearch": "Limpiar busqueda",
                                    "clearSelected": "Limpiar Seleccion",
                                    "noOptions": "Sin opciones",
                                    "search": "Buscar",
                                    "selectAll": "Todos",
                                    "selectAllFiltered": "Todos (Filtrado)",
                                    "selectSomeItems": "Seleccionar Secciones",
                                    "create": "Create",
                                }
                                }
                                options={Secciones}
                                value={sSecciones}
                                onChange={setSS}
                                labelledBy="Select"
                            />}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <MultiSelect
                            overrideStrings={{
                                "allItemsAreSelected": "Todo Seleccionado.",
                                "clearSearch": "Limpiar busqueda",
                                "clearSelected": "Limpiar Seleccion",
                                "noOptions": "Sin opciones",
                                "search": "Buscar",
                                "selectAll": "Todos",
                                "selectAllFiltered": "Todos (Filtrado)",
                                "selectSomeItems": "Seleccionar Puntos",
                                "create": "Create",
                            }
                            }
                            options={userData.permiso === "admin" ?
                                Sucursales :
                                Sucursales.filter(el => { return userData.puntos.split(",").indexOf(el.id.toString()) > -1 })}
                            value={sPuntos}
                            onChange={setSPtos}
                            labelledBy="Select"
                        />
                    </Col>
                </Row>
                {logData.id === 49 ? 
                <Row>
                    <Col>
                        <MultiSelect
                            overrideStrings={{
                                "allItemsAreSelected": "Todo Seleccionado.",
                                "clearSearch": "Limpiar busqueda",
                                "clearSelected": "Limpiar Seleccion",
                                "noOptions": "Sin opciones",
                                "search": "Buscar",
                                "selectAll": "Todos",
                                "selectAllFiltered": "Todos (Filtrado)",
                                "selectSomeItems": "Seleccionar Secciones",
                                "create": "Create",
                            }
                            }
                            options={Secciones}
                            value={sSecciones}
                            onChange={setSS}
                            labelledBy="Select"
                        />
                    </Col>
                </Row> : null}
                <Row className='mt-3 m-1'>
                    <Button type="submit" className="App-button m-2 mt-0" disabled={loader}>
                        {loader ? <BarLoader className="m-2" color="#FFF" width={100} /> : <>Aplicar filtros <BiSearchAlt /></>}
                    </Button>
                </Row>
            </Form>
            {consulta ?
                <div className="content-body-alt mb-4">
                    <Row className="row-excel-button">
                        <h3 className="p-2" style={{ width: '50vw' }}>Estadisticas de Encuesta</h3>
                        <Row>
                            <Col>
                                <h6 className="pb-2">Numero de encuestas realizadas por Sucursales</h6>
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Col>
                            <Table className="App-Table p-1" striped responsive hover size="sm">
                                <thead>
                                    <tr>
                                        <th className="table-th">Sucursal</th>
                                        {PS.filter(p => { if (DF.filter(d => { return d.periodo_id === p.id }).length > 0) { return p } })
                                            .map(p => { return <th className="table-th">{p.nombre_periodo}</th> })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {FP.map(p => {
                                        return <tr>
                                            <td>{p.nombre_sucursal}</td>
                                            {PS.filter(p => { if (DF.filter(d => { return d.periodo_id === p.id }).length > 0) { return p } })
                                                .map(per => {
                                                    return <td className='text-center' >
                                                        {DF !== undefined ? DF.filter(d => { return d.periodo_id === per.id && d.sucursal === p.id }).length : "NaN"}
                                                    </td>
                                                })}
                                        </tr>
                                    })}
                                    <tr>
                                        <td>Total:</td>
                                        {PS.filter(p => { if (DF.filter(d => { return d.periodo_id === p.id }).length > 0) { return p } })
                                            .map(per => { return <td className='text-center'>{DF !== undefined ? DF.filter(d => { return d.periodo_id === per.id }).length : "NaN"}</td> })}
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
                : null}
        </div>
    )
}