import { useState, useContext, useEffect, useReducer } from "react";
import { Form, Button, Table, Row, Col, Modal, Toast, ToastContainer, } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { BarLoader } from "react-spinners";
import ParametrosContext from "../../../Context/ParametrosContext";
import ClientContext from "../../../Context/ClientContext";
import UserContext from "../../../Context/UserContext";
import { HiOutlineVideoCamera, HiChevronDown } from 'react-icons/hi'
import ReactPlayer from 'react-player';
import axios from "axios";
import YearFilter from "../../shared/YearFilter";

export default function ListarVideos() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    const { paramData } = useContext(ParametrosContext);
    const { logData } = useContext(ClientContext);
    const { userData } = useContext(UserContext);

    const [loader, setLoad] = useState(false)
    const [consulta, setConsulta] = useState(false)
    const [videos, setVideos] = useState([])
    const [FV, setFV] = useState([])
    const [videoView, setVideoV] = useState(false)
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });

    const [selectedYear, setSelectedYear] = useState(null)
    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])
    const [secciones, setSecciones] = useState([])
    const [puntos, setPuntos] = useState([])
    const [Basencuestas, setBaseEncuestas] = useState([])
    const [joints, setJoints] = useState([])

    const [preguntas, setPreguntas] = useState([])
    const [sPuntos, setSPtos] = useState([])
    const [Secciones, setS] = useState([])
    const [enc, setE] = useState()
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const [respuestas, setR] = useState([])
    const [cities, setCities] = useState([])
    const [Sucursales, setSuc] = useState([]) //filtro de sucursales.
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },

    } = useForm({ mode: "onBlur" });

    const showView = (video) => {
        setValue("client_id", logData.id)
        setValue("nombre_video", video.nombre_video)
        setValue("link_video", video.link_video)
        setValue("video_id", video.id)
        setValue("resultado", video.resultado)
        setVideoV(true)
    }
    const hideView = () => { setVideoV(false) }

    function getOccurrence(array, value) { return respuestas.filter((v) => (v.encuesta_id === value)).length; }

    function showData() {
        let tabs = Tabulada()
        let helper = []
        tabs.forEach(v => {
            if (v.Tipo_pregunta !== "pregunta") {
                const bat = getOccurrence(tabs, v.Encuesta_id)
                if (bat > 1) {
                    const indet = helper.findIndex(o => o.Punto_id === v.Punto_id && o.Seccion_id === v.Seccion_id)
                    if (indet === -1) {
                        let temp = tabs.filter((q) => (q.Punto_id === v.Punto_id && q.Seccion_id === v.Seccion_id && q.Tipo_pregunta !== "pregunta"))
                        var result = {
                            Reporte: v.Reporte,
                            Encuesta_id: v.Encuesta_id,
                            Encuesta: v.Encuesta,
                            Nombre_sucursal: v.Nombre_sucursal,
                            Punto_id: v.Punto_id,
                            Periodo_id: v.Periodo_id,
                            Seccion: v.Seccion,
                            Seccion_id: v.Seccion_id,
                            Value: (temp.reduce(function (acc, obj) { return acc + obj.Value; }, 0) / temp.length).toFixed(2)
                        }
                        helper.push(result)
                    }
                }
                else { helper.push(v) }
            }
        })
        const values = getValues()
        if (values.periodo_id > 0 && values.seccion_id > 0) {
            let temp2 = videos.filter(v => v.periodo_id === Number(values.periodo_id) && v.seccion_id === Number(values.seccion_id) && v.encuesta_id === Number(enc.id))
            let temp3 = temp2.filter(el => { return sPuntos.some(p => { return p.id === el.punto_id }) })
            if (userData.permiso === "admin" || userData.permiso === "gerente") {
                setFV(temp3)
            }
            else {
                setFV(temp3.filter(v => { return v.estado !== "inactivo" }))
            }
            setConsulta(true)
        }
    }

    function Tabulada() {
        let data;
        let sum = []
        respuestas.forEach((r) => {
            if (r.tipo_pregunta === 'escala-tri') {
                if (r.respuesta === 'malo') {
                    data = {
                        Respuesta_id: r.id,
                        Value: 0,
                        Reporte_id: r.reporte_id,
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id,
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id: r.video_id
                    }
                    sum.push(data)
                }
                if (r.respuesta === 'regular') {
                    data = {
                        Respuesta_id: r.id,
                        Value: 50,
                        Reporte_id: r.reporte_id,
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id,
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id: r.video_id
                    }
                    sum.push(data)
                }
                if (r.respuesta === 'excelente') {
                    data = {
                        Respuesta_id: r.id,
                        Value: 100,
                        Reporte_id: r.reporte_id,
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id,
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id: r.video_id
                    }
                    sum.push(data)
                }
            }
            if (r.tipo_pregunta === "escala") {
                data = {
                    Respuesta_id: r.id,
                    Value: (r.respuesta * 100) / r.valor_max,
                    Reporte_id: r.reporte_id,
                    Punto_id: r.punto_id,
                    Periodo_id: r.periodo_id,
                    Encuesta_id: r.encuesta_id,
                    Seccion_id: r.seccion_id,
                    Pregunta: r.pregunta,
                    Tipo_pregunta: r.tipo_pregunta,
                    Video_id: r.video_id
                }
                sum.push(data)
            }
            if (r.tipo_pregunta === "thumbs") {
                if (r.respuesta === "si") {
                    data = {
                        Respuesta_id: r.id,
                        Value: 100,
                        Reporte_id: r.reporte_id,
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id,
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id: r.video_id
                    }
                    sum.push(data)
                }
                else if (r.respuesta === "no") {
                    data = {
                        Respuesta_id: r.id,
                        Value: 0,
                        Reporte_id: r.reporte_id,
                        Punto_id: r.punto_id,
                        Periodo_id: r.periodo_id,
                        Encuesta_id: r.encuesta_id,
                        Seccion_id: r.seccion_id,
                        Pregunta: r.pregunta,
                        Tipo_pregunta: r.tipo_pregunta,
                        Video_id: r.video_id
                    }
                    sum.push(data)
                }
            }
        })
        return sum;
    }
    // Carga de periodos basados en `selectedYear`
    useEffect(() => {
        if (!selectedYear) return;

        axios.get(`${API_URL}/periodo`, { params: { client_id: logData.id, year: selectedYear } })
            .then((response) => {
                const periodos = response.data
                    .map((p) => ({
                        ...p,
                        label: p.nombre_periodo,
                        value: p.id,
                    }))
                    .sort((a, b) => new Date(a.periodo_inicio) - new Date(b.periodo_inicio));
                setPeriodos(periodos);
            })
            .catch((error) => console.error("Error al cargar periodos:", error));
    }, [selectedYear, logData.id]); // Se ejecuta cuando `selectedYear` cambia  

    useEffect(() => {
        if (userData.permiso === 'admin') { axios.get(API_URL + '/video', { params: { client_id: logData.id } }).then((response) => { setVideos(response.data) }) }
        else {
            axios.get(API_URL + '/video', { params: { client_id: userData.cliente_id } }).then(
                (response) => {
                    setVideos(response.data)
                })
        }
        axios.get(API_URL + '/joint', { params: { client_id: Number(logData.id) } }).then(res => { setJoints(res.data) })
        axios.get(API_URL + '/ciudad', { params: { client_id: logData.id } }).then((response) => { response.data.forEach(p => { p.label = p.nombre_ciudad; p.value = p.id; }); setCities(response.data); });
        axios.get(API_URL + '/sucursal', { params: { cliente_id: logData.id } }).then((res) => {
            res.data.forEach(p => { p.label = p.nombre_sucursal; p.value = p.id; })
            if (logData.id === 49) { setPuntos(res.data) }
            else { setSuc(res.data) }
        })
        axios.get(API_URL + '/seccion/report', { params: { client_id: logData.id } }).then(
            (response) => {
                response.data.forEach(p => {
                    p.label = p.nombre_seccion;
                    p.value = p.id;
                })
                setSecciones(response.data)
            })
        axios.get(API_URL + '/encuesta/all', { params: { client_id: logData.id } }).then(
            (response) => {
                let n;
                let temp;
                if (typeof response.data === 'object') { temp = Object.keys(response.data).map((key) => response.data[key]); }
                else { temp = response.data }
                if (userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null && encuesta.tipo_encuesta === "mystery"); }
                else { let vat = temp.filter(el => { return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); n = vat.filter(encuesta => encuesta.punto_encuesta !== null && encuesta.tipo_encuesta === "mystery"); }
                setBaseEncuestas(n)
            })
    }, [toast])

    const sortByPoint = () => {
        let temp = FV.sort(function (a, b) { return a.punto_id - b.punto_id })
        setFV(temp)
        forceUpdate()
    }
    const sortByScore = () => {
        let temp = FV.sort(function (a, b) { return Number(b.resultado.substring(0, b.resultado.length - 1)) - Number(a.resultado.substring(0, a.resultado.length - 1)) })
        setFV(temp)
        forceUpdate()
    }


    function Toaster(variant, message) { setToast({ show: true, variant: variant, message: message }); }

    const handleEncuesta = (e) => {
        setLoad(true)
        e.preventDefault()
        setValue("seccion_id", null)
        let sec = []
        if (userData.permiso === "admin") { sec = secciones }
        else {
            sec = secciones.filter(el => { return userData.secciones.split(",").indexOf(el.id.toString()) > -1 })
        }
        let en = encuestas.find(el => el.id === Number(e.target.value))
        let tem = sec.filter(s => { return s.encuesta_id === Number(e.target.value) })
        if (en) {
            axios.post(API_URL + '/pregunta/active', { encuesta_id: Number(e.target.value) }).then((res) => {
                let pregs = res.data.preguntas.filter(p => { return p.pausa < 1 })
                let numbered = pregs.filter(p => Number(p.orden) > 0)
                let unnumbered = pregs.filter(p => Number(p.orden <= 0))
                numbered = numbered.sort((a, b) => Number(a.orden) > Number(b.orden) ? 1 : -1)
                let preg = [...numbered, ...unnumbered]
                setPreguntas(preg)
            })
            axios.get(API_URL + '/respuesta', { params: { encuesta_id: Number(e.target.value), periodo_id: getValues("periodo_id") } }).then(res => {
                setR(res.data); setLoad(false)
            })
            setE(en)
            setS(tem)
            forceUpdate()
        }
    }

    function getClass(val) {
        let x
        if (val !== undefined && val !== null) { x = Number(val.substring(0, val.length - 1)) }
        else { return }
        if (isNaN(x) || val === null) { return "Fondo-Blank" }
        if (x < paramData.values.amarillo) { return "Fondo-R" }
        if (x < paramData.values.verde) { return "Fondo-A" }
        if (x >= paramData.values.verde) { return "Fondo-V" }
    }
    const handlePerdiod = (e) => {
        //check if encuesta esta seleccionada.
        if (enc) {
            setLoad(true)
            axios.get(API_URL + '/respuesta', { params: { encuesta_id: Number(enc.id), periodo_id: e.target.value } }).then(res => {
                setR(res.data); setLoad(false)
            })
        }
        setValue("periodo_id", Number(e.target.value))
        setEncuestas([])
        if (e && e.target.value !== null && e.target.value !== undefined) {
            let temp = joints.filter(j => { return Number(j.periodo_id) === Number(e.target.value) })
            let temp2 = Basencuestas.filter(e => { return temp.some(j => { return Number(j.encuesta_id) === Number(e.id) }) })
            setEncuestas(temp2)
        }
    }
    const handleCity = (e) => {
        e.preventDefault()
        setSPtos([])
        let sucursales = puntos.filter(s => { return Number(s.ciudad_id) === Number(e.target.value) })
        setSuc(sucursales)
    }
    return (<>
        <ToastContainer style={{ position: "fixed", top: "80vh", right: "0vw" }} className="p-3">
            <Toast
                bg={toast.variant}
                onClose={() => setToast({ show: false, variant: "", message: "" })}
                show={toast.show}
                delay={2000}
                autohide>
                <Toast.Header>
                    <strong className="me-auto">Medicion</strong>
                    <small>just now</small>
                </Toast.Header>
                <Toast.Body className="text-white">{toast.message}</Toast.Body>
            </Toast>
        </ToastContainer>
        <Modal show={videoView} onHide={hideView} centered fullscreen>
            <Modal.Header closeButton>
                <Modal.Title>Ver Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div style={{ position: 'fixed', width: "45vw" }} className="p-2">
                            <ReactPlayer url={getValues("link_video")} controls={true} loop={true} width={"100%"} />
                        </div>
                    </Col>
                    <Col className="hidden-sm">
                        <div className="video-form">
                            <div className="top-bubble"></div>
                            {respuestas ?
                                preguntas.filter(p => { return Number(getValues("seccion_id")) === Number(p.seccion_id) })
                                    .sort((a, b) => a.orden > b.orden ? 1 : -1)
                                    .map(p => {
                                        return <Row className="m-2">
                                            <Row>{p.titulo}</Row>
                                            <Row className="video-response">{respuestas.find(item => { return item.video_id === Number(getValues("video_id")) && Number(item.pregunta_id) === p.id }) ?
                                                respuestas.find(item => { return item.video_id === Number(getValues("video_id")) && Number(item.pregunta_id) === p.id }).respuesta : "na"}</Row>
                                        </Row>
                                    }) : null}
                            <div className="bottom-bubble"></div>
                        </div>
                        <div className="m-3 result-display">
                            <h5>Resultado:</h5>
                            <h3 className="m-2 mt-0 mb-0">{getValues("resultado")}</h3>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="hidden-lg mt-2">
                        <div className="video-form">
                            <div className="top-bubble"></div>
                            {respuestas ? respuestas.filter(item => item.video_id === Number(getValues("video_id")))
                                .map(p => {
                                    return <Row className="m-2">
                                        <Row>{p.pregunta}</Row>
                                        <Row className="video-response">{p.respuesta}</Row>
                                    </Row>
                                })
                                : null}
                            <div className="bottom-bubble"></div>
                        </div>
                        <div className="m-3 result-display">
                            <h5>Resultado:</h5>
                            <h3 className="m-2 mt-0 mb-0">{getValues("resultado")}</h3>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="App-button-alt" onClick={hideView}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
        <div className="content-container">
            <Form>
                <Row className="mb-1">
                    <Col>
                        <YearFilter selectedYear={selectedYear} setSelectedYear={setSelectedYear} />
                    </Col>
                    <Col>
                        <Form.Select onChange={handlePerdiod}>
                            <option value={null}>Sin Periodo Seleccionado</option>
                            {periodos.map(enc => { return <option value={enc.id}>{enc.nombre_periodo}</option> })}
                        </Form.Select>
                    </Col>

                </Row>
                <Row className="mb-1">
                    <Col>
                        <Form.Select onChange={handleEncuesta}>
                            <option value={null}>Sin Encuesta Seleccionada</option>
                            {encuestas.map(enc => { return <option value={enc.id}>{enc.nombre_encuesta}</option> })}
                        </Form.Select>
                    </Col><Col>
                        {logData.id === 49 ?
                            <Form.Select onChange={handleCity} >
                                <option value={null}>Sin Ciudad Seleccionada</option>
                                {cities.length > 0 ? cities.map(enc => { return <option value={enc.id}>{enc.nombre_ciudad}</option> }) : null}
                            </Form.Select> :
                            <Form.Select {...register("seccion_id")}>
                                <option value={null}>Sin Seccion Seleccionada</option>
                                {Secciones.map(enc => { return <option value={enc.id}>{enc.nombre_seccion}</option> })}
                            </Form.Select>}
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <MultiSelect overrideStrings={{
                            "allItemsAreSelected": "Todos los Puntos Seleccionados.",
                            "clearSearch": "Limpiar busqueda",
                            "clearSelected": "Limpiar Seleccion",
                            "noOptions": "Sin opciones",
                            "search": "Buscar",
                            "selectAll": "Todos",
                            "selectAllFiltered": "Todos (Filtrado)",
                            "selectSomeItems": "Seleccionar Puntos",
                            "create": "Create",
                        }}
                            options={userData.permiso === "admin" ?
                                Sucursales :
                                Sucursales.filter(el => { return userData.puntos.split(",").indexOf(el.id.toString()) > -1 })}
                            value={sPuntos}
                            onChange={setSPtos}
                            labelledBy="Select"
                        />
                    </Col>
                </Row>
                {logData.id === 49 ? <Row className="mb-1">
                    <Col>
                        <Form.Select {...register("seccion_id")}>
                            <option value={null}>Sin Seccion Seleccionada</option>
                            {Secciones.map(enc => { return <option value={enc.id}>{enc.nombre_seccion}</option> })}
                        </Form.Select>
                    </Col>
                </Row> : null}
                <Row className="m-1 mt-3">
                    <Button className="App-button m-2 mt-0" disabled={loader} onClick={() => { showData() }}>
                        {loader ? <BarLoader className="m-2" color="#FFF" width={100} /> : <>Mostrar videos</>}
                    </Button>
                </Row>
            </Form>
            {consulta ?
                <div className="content-body-alt mb-4">
                    <h3 className="p-2">Videos</h3>
                    <Table className="App-Table" striped responsive="md" hover size="sm">
                        <thead>
                            <tr>
                                <th className="table-th">nombre de video</th>
                                <th className="table-th" onClick={sortByPoint}>punto<HiChevronDown /></th>
                                <th className="table-th">seccion</th>
                                <th className="table-th" onClick={sortByScore}>resultado<HiChevronDown />(%)</th>
                                <th className="table-th">ver</th>
                            </tr>
                        </thead>
                        <tbody>
                            {FV.map((video) =>
                                <tr>
                                    <td>{video.nombre_video}</td>
                                    {Sucursales && Sucursales.find(p => p.id === video.punto_id) ? <td>{Sucursales.find(p => p.id === video.punto_id).nombre_sucursal}</td> : <td></td>}
                                    {secciones && secciones.find(p => p.id === video.seccion_id) ? <td>{secciones.find(p => p.id === video.seccion_id).nombre_seccion}</td> : <td></td>}
                                    <td className={getClass(video.resultado)} >{video.resultado}</td>
                                    <td className="clickable-end" onClick={() => showView(video)}>
                                        <HiOutlineVideoCamera />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div> : null}
        </div>
    </>)
}