import { useState, useContext, useEffect } from 'react'
import { Form, Button, Table, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { BiSearchAlt } from 'react-icons/bi'
import { BarLoader } from 'react-spinners';
import { format } from 'date-fns';
import ClientContext from '../../../Context/ClientContext';
import UserContext from '../../../Context/UserContext';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import axios from 'axios';
import YearFilter from '../../shared/YearFilter';

export default function DataCruda() {

    const API_URL = 'https://api.medicionanalytic.com/api'
    const { logData } = useContext(ClientContext)
    const { userData } = useContext(UserContext)

    const [sPeriods, setSP] = useState([])
    const [sPuntos, setSPtos] = useState([])
    const [sSecciones, setSS] = useState([])

    const [Secciones, setS] = useState([])
    const [enc, setE] = useState()


    const [selectedYear, setSelectedYear] = useState(null) //constante para un año seleccionado
    const [periodos, setPeriodos] = useState([])
    const [encuestas, setEncuestas] = useState([])
    const [secciones, setSecciones] = useState([])
    const [puntos, setPuntos] = useState([])
    const [reportes, setRep] = useState([])

    const [dataC, setCruda] = useState([])
    const [dataB, setBase] = useState([])
    const [consulta, setConsulta] = useState(false)
    const [loader, setLoad] = useState(false)
    const {
        register,
        handleSubmit,
        getValues,
    } = useForm({ mode: "onBlur" });
    //format for excel Download
    const fileType = 'aplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        //añadir campos extra.
        let Document = dataC
        Document.forEach((d) => {
            let rep = reportes.find(r => r.id === d.reporte_id)
            delete d.updated_at; delete d.video_id; delete d.segmento;
            delete d.comentario; delete d.cargo; delete d.gerencia; delete d.imagen; delete d.genero; delete d.created_at;
            if (rep !== undefined) { d.hora_evaluacion = format(rep.created_at, 'dd/MM/H:mm') }
            else { d.hora_evaluacion = "tiempo no disponible" }
            if (rep !== undefined) { d.encuestado = rep.encuestado }
            else { d.encuestado = "Encuestado no disponible" }
            d.periodo = periodos.find(p => p.id === d.periodo_id).nombre_periodo
            d.sucursal = puntos.find(pt => pt.id === d.punto_id).label
            d.encuesta = encuestas.find(e => e.id === d.encuesta_id).nombre_encuesta
            d.seccion = secciones.find(s => s.id === d.seccion_id).nombre_seccion
            if (d.tipo_pregunta !== 'escala') { d.valor_max = null; }
            if (d.aplica == 1) { d.aplica = "si" }
            else { d.aplica = "no" }
            d.tipo_pregunta = getPreguntaType(d.tipo_pregunta)
        })
        const ws = XLSX.utils.json_to_sheet(Document) //pass jason data
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'Excel Export' + fileExtension)
    }
    useEffect(() => {
        axios.get(API_URL + '/sucursal', { params: { cliente_id: logData.id } }).then((res) => {
            res.data.forEach(p => {
                p.label = p.nombre_sucursal;
                p.value = p.id;
            })
            setPuntos(res.data)
        })
        axios.get(API_URL + '/seccion/report', { params: { client_id: logData.id } }).then((response) => {
            response.data.forEach(p => {
                p.label = p.nombre_seccion;
                p.value = p.id;
            })
            setSecciones(response.data)
        })
        axios.get(API_URL + '/encuesta/all', { params: { client_id: logData.id } }).then((response) => {
            let n;
            let temp;
            if (typeof response.data === 'object') { temp = Object.keys(response.data).map((key) => response.data[key]); }
            else { temp = response.data }
            if (userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null); }
            else { let vat = temp.filter(el => { return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); n = vat.filter(encuesta => encuesta.punto_encuesta !== null); }
            setEncuestas(n)
        })
    }, []);
    // Carga de periodos basados en `selectedYear`
    useEffect(() => {
        if (!selectedYear) return;

        axios.get(`${API_URL}/periodo`, { params: { client_id: logData.id, year: selectedYear } })
            .then((response) => {
                const periodos = response.data
                    .map((p) => ({
                        ...p,
                        label: p.nombre_periodo,
                        value: p.id,
                    }))
                    .sort((a, b) => new Date(a.periodo_inicio) - new Date(b.periodo_inicio));
                setPeriodos(periodos);
            })
            .catch((error) => console.error("Error al cargar periodos:", error));
    }, [selectedYear, logData.id]); // Se ejecuta cuando `selectedYear` cambia

    const handleEncuesta = (e) => {
        setLoad(true)
        e.preventDefault()
        setSS([])
        let sec = []
        if (userData.permiso === "admin") { sec = secciones }
        else {
            sec = secciones.filter(el => { return userData.secciones.split(",").indexOf(el.id.toString()) > -1 })
        }

        let enc = encuestas.find(el => el.id === Number(e.target.value))
        let tem = sec.filter(s => { return s.encuesta_id === Number(e.target.value) })
        if (enc) {
            axios.get(API_URL + '/respuesta', { params: { encuesta_id: Number(e.target.value), periodo_id: sPeriods } }).then(res => { Tabulada(res.data) })
            axios.get(API_URL + '/reporte', { params: { encuesta: Number(e.target.value) } }).then(res => { setRep(res.data) })
            setE(enc)
            setS(tem)
        }
    }

    function Tabulada(DataC) {
        let res = DataC
        res.forEach((r) => {
            if (r.tipo_pregunta === 'escala-tri') {
                if (r.respuesta === 'malo') { r.Valor = "0%" }
                if (r.respuesta === 'regular') { r.Valor = "50%" }
                if (r.respuesta === 'excelente') { r.Valor = "100%" }
            }
            if (r.tipo_pregunta === "escala") { r.Valor = (r.respuesta * 100) / r.valor_max }
            if (r.tipo_pregunta === "thumbs") {
                if (r.respuesta === "si") {
                    r.Valor = "100%"
                }
                else if (r.respuesta === "no") {
                    r.Valor = "0%"
                }
            }
            if (r.tipo_pregunta === "nps" || (r.tipo_pregunta === "npsna" && r.respuesta !== "na")) { r.Valor = (r.respuesta * 100) / 10 }
            if (r.tipo_pregunta === "stars") { r.Valor = (r.respuesta * 100) / 5 }
            if (r.tipo_pregunta === "smiles") { r.Valor = (r.respuesta * 100) / 5 }
        })
        setBase(res)
        setLoad(false)
    }

    function getPreguntaType(x) {
        switch (x) {
            case 'escala-tri': return "malo-excelente";
            case 'thumbs': return "si/no";
            case 'escala': return "escala";
            case 'nps': return "nps";
            case 'npsna': return "npsna";
            case "smiles": return "felicidad";
            case "comentario": return "comentario";
            case "stars": return "estrellas";
            case "texto": return "pregunta";
            case "info": return "informacion";
            case "select": return "seleccion";
            case "selectm": return "seleccion multiple";
            case "load": return "archivos";
            default: return "pregunta abierta";
        }
    }

    const SetFilters = (data, e) => {
        e.preventDefault();
        if (!enc) { setConsulta(false); return; }
        let values = getValues()
        let repo = reportes.filter(d => dataB.find(r => r.reporte_id === d.id))
        let init = new Date(values.date_init.replace(/-/g, '\/').replace(/T.+/, ''))
        let end = new Date(values.date_fin.replace(/-/g, '\/').replace(/T.+/, ''))
        let reps = repo.filter(r => {
            let created = new Date(format(r.created_at, 'yyyy/MM/dd').replace(/-/g, '\/').replace(/T.+/, ''))
            if ((created.getTime() <= end.getTime()) && (created.getTime() >= init.getTime())) { return sPuntos.some(p => { return sPeriods.some(f => { return p.id === r.sucursal && f.id === r.periodo_id }) }) }
        })
        let temp = []
        temp = dataB.filter(el => {
            return sPuntos.some(p => {
                return sSecciones.some(s => {
                    return sPeriods.some(f => {
                        return reps.some(rep => {
                            return p.id === el.punto_id &&
                                s.id === el.seccion_id &&
                                f.id === el.periodo_id &&
                                el.encuesta_id === enc.id &&
                                el.reporte_id === rep.id
                        })
                    })
                })
            })
        })
        let soc = []
        temp.forEach(t => {
            if (soc.indexOf(t.reporte_id) < 0) {
                soc.push(t.reporte_id)
            }
        })
        console.log(reps.filter(rep => { return !soc.some(s => { return Number(s) === Number(rep.id) }) }))
        if (temp.length >= 0) {
            setCruda(temp)
            setConsulta(true)
        }
        else { setConsulta(false) }
    }

    return (
        <div className="content-container">
            <Form onSubmit={handleSubmit(SetFilters)}>
                <Row className="mb-1">
                    <Col>
                        <YearFilter selectedYear={selectedYear} setSelectedYear={setSelectedYear} />
                    </Col>
                    <Col>
                        {/* MultiSelect de periodos */}
                        <MultiSelect className='selecto'
                            overrideStrings={{
                                allItemsAreSelected: "Todo Seleccionado.",
                                clearSearch: "Limpiar búsqueda",
                                clearSelected: "Limpiar Selección",
                                noOptions: "Sin opciones",
                                search: "Buscar",
                                selectAll: "Todos",
                                selectAllFiltered: "Todos (Filtrado)",
                                selectSomeItems: "Seleccionar Periodo",
                                create: "Create",
                            }}
                            options={periodos}
                            value={sPeriods}
                            onChange={setSP}
                            labelledBy="Select"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Select onChange={handleEncuesta} >
                            <option classNamevalue={null}>Sin Encuesta Seleccionada</option>
                            {encuestas.length > 0 ? encuestas.map(enc => { return <option value={enc.id}>{enc.nombre_encuesta}</option> }) : null}
                        </Form.Select>
                    </Col>
                    <Col>
                        <MultiSelect className='selecto'
                            overrideStrings={{
                                "allItemsAreSelected": "Todo Seleccionado.",
                                "clearSearch": "Limpiar busqueda",
                                "clearSelected": "Limpiar Seleccion",
                                "noOptions": "Sin opciones",
                                "search": "Buscar",
                                "selectAll": "Todos",
                                "selectAllFiltered": "Todos (Filtrado)",
                                "selectSomeItems": "Seleccionar Secciones",
                                "create": "Create",
                            }
                            }
                            options={Secciones}
                            value={sSecciones}
                            onChange={setSS}
                            labelledBy="Select"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <MultiSelect className='selecto'
                            overrideStrings={{
                                "allItemsAreSelected": "Todo Seleccionado.",
                                "clearSearch": "Limpiar busqueda",
                                "clearSelected": "Limpiar Seleccion",
                                "noOptions": "Sin opciones",
                                "search": "Buscar",
                                "selectAll": "Todos",
                                "selectAllFiltered": "Todos (Filtrado)",
                                "selectSomeItems": "Seleccionar Puntos",
                                "create": "Create",
                            }
                            }
                            options={userData.permiso === "admin" ?
                                puntos :
                                puntos.filter(el => { return userData.puntos.split(",").indexOf(el.id.toString()) > -1 })}
                            value={sPuntos}
                            onChange={setSPtos}
                            labelledBy="Select"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label className='m-2 mt-1 mb-1'>Fecha inicio</Form.Label>
                        <Form.Control className='rmsc' type='date' {...register("date_init")} />
                    </Col>
                    <Col>
                        <Form.Label className='m-2 mt-1 mb-1'>Fecha fin</Form.Label>
                        <Form.Control className='rmsc' type='date' {...register("date_fin")} />
                    </Col>
                </Row>
                <Row className='mt-3 m-1'>
                    <Button className="App-button m-2 mt-0" disabled={!consulta} onClick={exportToExcel}>Descargar Excel</Button>
                    <Button type="submit" className="App-button m-2 mt-0" disabled={loader}>
                        {loader ? <BarLoader className="m-2" color="#FFF" width={100} /> : <>Aplicar filtros <BiSearchAlt /></>}
                    </Button>
                </Row>
            </Form>
            <div className="content-body-alt mb-4">
                {consulta ? <Table className="App-Table p-1" striped responsive="md" hover size="sm">
                    <thead>
                        <tr>
                            <th className='table-th'>Periodo</th>
                            <th className='table-th'>Periodo_id</th>
                            <th className='table-th'>Encuesta</th>
                            <th className='table-th'>Encuesta_id</th>
                            <th className='table-th'>Tipo_pregunta</th>
                            <th className='table-th'>Respuesta</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataC.filter((item, idx) => idx < 15).map(res => {
                            return <tr>
                                <td className='table-td'>{periodos.find(p => p.id === res.periodo_id).nombre_periodo}</td>
                                <td className='table-td'>{res.periodo_id}</td>
                                <td className='table-td'>{encuestas.find(e => e.id === res.encuesta_id).nombre_encuesta}</td>
                                <td className='table-td'>{res.encuesta_id}</td>
                                <td className='table-td'>{getPreguntaType(res.tipo_pregunta)}</td>
                                <td className='table-td'>{res.respuesta}</td>
                            </tr>
                        })}
                    </tbody>
                </Table> : null}
            </div>
        </div>
    )
}