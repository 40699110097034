import {useState, useContext, useEffect} from "react"
import { Button, Form, Row, Col, Modal, Table, Toast, ToastContainer } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ClientContext from "../../../Context/ClientContext";
import axios from "axios";
import { BiEditAlt, BiTrash } from "react-icons/bi";

export default function Ciudades() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    const { logData } = useContext(ClientContext)
    const newLocation = useNavigate()
    const [paises, setPaises] = useState([])
    const [zonas, setZonas] = useState([])
    const [ciudades, setCiudades] = useState([])
    const [sPais, setSPais] = useState()
    const [sZona, setSZona] = useState()
    const [show, setShow] = useState(false)
    const [showEdit, setEditShow] = useState(false)
    const [viewEliminar, setViewEliminar] = useState(false)
    const [toast, setToast] = useState({ show: false, variant: "", message: "" });
    const {
        register,
        handleSubmit,
        reset,        
        formState: { errors, isValid },
      } = useForm({ mode: "onBlur" });
      
    useEffect(() => {
        axios.get(API_URL + '/pais').then((response) =>
        {setPaises(response.data)})
    }, [])

    const handleClose = () => {updateList();setShow(false);}
    const handleShow = () => {
        reset({
            id: null,
            nombre_ciudad: null,
            abreviatura: null,
            estado: "activo",
        })
        setShow(true);}
    const hideEliminar = () => {setViewEliminar(false);updateList();}
    const showEliminar = (ciudad) => {
            reset({
                id: ciudad.id,
                nombre_ciudad: ciudad.nombre_ciudad,
                abreviatura: ciudad.abreviatura,
                estado: ciudad.estado,    
            })
            setViewEliminar(true)
        }
    const handleEditClose = () => {updateList();setEditShow(false);}
    const handleEditShow = (ciudad) => {
        reset({
            id: ciudad.id,
            nombre_ciudad: ciudad.nombre_ciudad,
            abreviatura: ciudad.abreviatura,
            estado: ciudad.estado,
        })
        setEditShow(true);}
    
    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
            };
        return headers;}

    const CallModal = (datax, e) => {
        e.preventDefault();
        axios.post(API_URL + '/ciudad', datax).then(
            function (response) {
                Toaster("success", response.data.message)
                handleClose() })
    }

    const EditCiudad = (data,e) => {
        e.preventDefault();
        let headers = setHeaders()
        axios.post(API_URL + '/ciudad/update', data, {headers}).then(
            function (response) {
                Toaster("success", response.data.message)
                handleClose() })
    }

    const RemoveCiudad = (data, e) => {
        e.preventDefault()
        let headers = setHeaders()
        axios.delete(API_URL+`/ciudad/${data.id}`, {headers}).then(res => {
            Toaster("success", res.data.message)
            hideEliminar()
        })
    }

    function updateList(){
        axios.get(API_URL + '/ciudad',{ params:{zona_id:sZona.id}}).then(
            (response) => {setCiudades(response.data)
                reset({
                    id: null,
                    nombre_ciudad: null,
                    abreviatura: null,
                    estado: "activo",
                })}          
            )}

 const handleChange = e => {
        e.preventDefault();
        let temp = paises.find((pais) => pais.id == e.target.value)
        axios.get(API_URL + '/zona',{ params:{pais_id:e.target.value, client_id: logData.id}}).then(
            (response) => {setZonas(response.data)}  
           )
        setSPais(temp);
    }
    const handleZonaChange = e => {
        e.preventDefault();
        let temp = zonas.find((zona) => zona.id == e.target.value)
        axios.get(API_URL + '/ciudad',{ params:{zona_id:e.target.value}}).then(
            (response) => {setCiudades(response.data)}  
           )
        setSZona(temp);
    }
    function Toaster(variant, message) {setToast({ show: true, variant: variant, message: message });}
    return (<>
    <ToastContainer style={{position:"fixed",top:"80vh", right:"0vw"}} className="p-3">
        <Toast
          bg={toast.variant}
          onClose={() => setToast({ show: false, variant: "", message: "" })}
          show={toast.show}
          delay={2000}
          autohide>
          <Toast.Header>
            <strong className="me-auto">Medicion</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{toast.message}</Toast.Body>
        </Toast>
    </ToastContainer>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nueva Ciudad</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(CallModal)}>
            <Modal.Body>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Zona</Form.Label>
                        <Form.Select {...register("zona_id")}>
                            <option>Seleccione una zona</option>
                            {zonas.map((zona) => <option value={zona.id}>{zona.nombre_zona}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Estado</Form.Label>                
                        <Form.Select {...register("estado")}>
                            <option value="activo">Activo</option>
                            <option value="inactivo">Inactivo</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Nombre de ciudad</Form.Label>
                        <Form.Control size="sm" placeholder="nombre" {...register("nombre_ciudad", {required:true})}/>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Abreviatura</Form.Label>
                        <Form.Control size="sm" {...register("abreviatura", {required:true})}/>
                    </Form.Group>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={handleClose}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button" disabled={!isValid} onClick={handleClose}>
                Crear
            </Button>
            </Modal.Footer>
        </Form>
      </Modal>
    <Modal show={showEdit} onHide={handleEditClose}>
        <Modal.Header closeButton>
          <Modal.Title>Actualizar Ciudad</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(EditCiudad)}>
            <Modal.Body>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Zona</Form.Label>
                        <Form.Select {...register("zona_id")}>
                            <option>Seleccione una zona</option>
                            {zonas.map((zona) => <option value={zona.id}>{zona.nombre_zona}</option>)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Estado</Form.Label>                
                        <Form.Select {...register("estado")}>
                            <option value="activo">Activo</option>
                            <option value="inactivo">Inactivo</option>
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Nombre de ciudad</Form.Label>
                        <Form.Control size="sm" placeholder="nombre" {...register("nombre_ciudad")}/>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-2">
                        <Form.Label>Abreviatura</Form.Label>
                        <Form.Control size="sm" {...register("abreviatura")}/>
                    </Form.Group>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={handleEditClose}>
                Cerrar
            </Button>
            <Button type="submit" className="App-button" >
                Actualizar
            </Button>
            </Modal.Footer>
        </Form>
      </Modal>
    <Modal show={viewEliminar} onHide={hideEliminar}>
    <Modal.Header closeButton>
          <Modal.Title>Eliminar Ciudad</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(RemoveCiudad)}>
            <Modal.Body>
                <h4>Esta seguro ?</h4>
                <h5>esta acción no puede deshacerse</h5>
            </Modal.Body>
            <Modal.Footer>
            <Button className="App-button-alt" onClick={hideEliminar}>Cerrar</Button>
                <Button type="submit" className="App-button">Eliminar</Button>
            </Modal.Footer>
        </Form>
    </Modal>

    <div className="content-container">
    <div className="content-header"><h4>País</h4></div>
        <Form>
            <Row className="mb-2">
                <Form.Group as={Col}>
                    <Form.Select onChange={handleChange}>
                        <option>Seleccionar Pais...</option>
                        {paises.map((pais) => <option value={pais.id}>{pais.nombre_pais}</option>)}
                    </Form.Select>
                </Form.Group>
            </Row>
            {sPais ? 
                <Row className="mb-2">
                    <Form.Group as={Col}>
                    <Form.Select onChange={handleZonaChange}>
                        <option>Seleccionar zona...</option>
                        {zonas.map((zona) => <option value={zona.id}>{zona.nombre_zona}</option>)}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                    <Button className="App-button" onClick={()=>newLocation("/app/administracion/zonas")}>Nueva zona</Button>
                </Form.Group>
                </Row>
            :null}
        </Form> 
        {sZona ? 
            <div className="content-body-alt">
                <div className="content-header"><h4>Ciudades</h4></div>
                <Button className="m-3 App-button" onClick={handleShow}> Nueva Ciudad </Button>
                <Table className="App-Table" striped responsive="md" hover size="sm">
                    <thead>
                        <tr>                            
                            <th className="table-th">Nombre de ciudad</th>
                            <th className="table-th">Abreviatura</th>                        
                            <th className="table-th">Estado</th>                        
                            <th></th>                        
                        </tr>
                    </thead>
                    <tbody>
                            {ciudades.map((ciudad) => 
                               <tr>                                    
                                    <td>{ciudad.nombre_ciudad}</td>
                                    <td>{ciudad.abreviatura}</td>
                                    <td>{ciudad.estado}</td>
                                    <td className="clickable-end">
                                        <td className="clickable"><BiEditAlt onClick={() => handleEditShow(ciudad)}/></td>
                                        <td className="clickable"><BiTrash onClick={() => showEliminar(ciudad)} /></td>
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </Table>
        </div>:null}
        <div className="flex-between">
        <Button className="App-button" onClick={() => {newLocation("../zonas")}}>Atrás</Button>
        <Button className="App-button" onClick={() => {newLocation("../puntos")}}>Siguiente</Button>
        </div>
    </div>
    </>)
}