import { useState, useContext, useEffect } from "react";
import { Form, Button, Row, Col, Table, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BiSearchAlt, BiEditAlt } from 'react-icons/bi'
import { ClipLoader, BarLoader } from "react-spinners";
import { format } from 'date-fns';
import ClientContext from "../../../Context/ClientContext";
import UserContext from "../../../Context/UserContext";
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import axios from "axios";


export default function GestionDetractor() {
    const API_URL = 'https://api.medicionanalytic.com/api'
    const { logData } = useContext(ClientContext)
    const { userData } = useContext(UserContext)
    const [consulta, setConsulta] = useState(false)

    const [reportes, setRep] = useState([])
    const [enc, setE] = useState()
    const [Reps, setReps] = useState([])
    const [detractores, setDet] = useState([])

    const [periodos, setPeriodos] = useState([])
    const [filteredPeriodos, setFilteredPeriodos] = useState([]);
    const [selectedYear, setSelectedYear] = useState(2025);
    const [encuestas, setEncuestas] = useState([])
    const [puntos, setPuntos] = useState([])
    const [cities, setCities] = useState([])
    const [Sucursales, setSuc] = useState([]) //filtro de sucursales.

    const [respuestas, setR] = useState([])
    const [PS, setPS] = useState({})
    const [Pnt, setPnt] = useState({})
    const [edit, setEdit] = useState(false)

    const [loader, setLoad] = useState(false)
    const [loadBuffer, setBuffer] = useState(false)
    const [Basencuestas, setBaseEncuestas] = useState([])
    const [joints, setJoints] = useState([])


    const showEdit = (detractor) => {
        reset({
            id: detractor.id,
            cliente_id: detractor.client_id,
            reporte_id: detractor.reporte_id,
            punto_id: detractor.punto_id,
            periodo_id: detractor.periodo_id,
            encuesta_id: detractor.encuesta_id,
            encuestado: detractor.encuestado,
            fecha: detractor.fecha,
            texto: detractor.texto,
            status: detractor.status,
        }); setEdit(true)
    }
    const hideEdit = () => { setEdit(false) }

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({ mode: "onBlur" });

    useEffect(() => {
        axios.get(API_URL + '/joint', { params: { client_id: Number(logData.id) } }).then(res => { setJoints(res.data) })
        axios.get(API_URL + '/ciudad', { params: { client_id: logData.id } }).then((response) => { response.data.forEach(p => { p.label = p.nombre_ciudad; p.value = p.id; }); setCities(response.data); });
        axios.get(API_URL + '/sucursal', { params: { cliente_id: logData.id } }).then((res) => {
            res.data.forEach(p => { p.label = p.nombre_sucursal; p.value = p.id; })
            if (logData.id === 49) { setPuntos(res.data) }
            else { setSuc(res.data) }
        })
        axios.get(API_URL + '/encuesta/all', { params: { client_id: logData.id } }).then((response) => {
            let n;
            let temp;
            if (typeof response.data === 'object') { temp = Object.keys(response.data).map((key) => response.data[key]); }
            else { temp = response.data }
            if (userData.permiso === "admin") { n = temp.filter(encuesta => encuesta.punto_encuesta !== null && encuesta.tipo_encuesta === "encuesta"); }
            else { let vat = temp.filter(el => { return userData.encuestas.split(",").indexOf(el.id.toString()) > -1 }); n = vat.filter(encuesta => encuesta.punto_encuesta !== null && encuesta.tipo_encuesta === "encuesta"); }
            setBaseEncuestas(n)
        })

    }, [])

    useEffect(() => {
        const fetchPeriodos = async () => {
            const params = { client_id: logData.id, year: selectedYear }; // Año por defecto 2025
            const response = await axios.get(`${API_URL}/periodo`, { params });
            const data = response.data.map((p) => ({
                ...p,
                year: new Date(p.periodo_inicio).getFullYear(),
                label: p.nombre_periodo,
                value: p.id,
            }));
            setPeriodos(data);
            setFilteredPeriodos(data); // Mostrar los periodos filtrados
        };

        fetchPeriodos();
    }, [logData.id, selectedYear]); // Dependencia en selectedYear para recargar datos si cambia


    function showData() { setConsulta(true); setLoad(false); }
    const setHeaders = () => {
        let token = window.localStorage.getItem("sessionKey");
        const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        };
        return headers;
    }
    const update = () => {
        axios.get(API_URL + '/detractor', { params: { encuesta_id: enc.id } }).then(res => {
            setDet(res.data)
        })
    }
    const gestionar = (detractor) => {
        let headers = setHeaders()
        axios.post(API_URL + '/detractor/gestion', detractor, { headers }).then(res => {
            hideEdit();
            update();
        }).catch(err => {
            console.log(err)
            hideEdit()
        })
    }

    const SetFilters = (data, e) => {
        setLoad(true)
        let values = getValues()
        e.preventDefault();
        if (!enc) { setConsulta(false); return; }
        let init = new Date(values.date_init.replace(/-/g, '\/').replace(/T.+/, ''))
        let end = new Date(values.date_fin.replace(/-/g, '\/').replace(/T.+/, ''))
        let reps = reportes.filter(r => {
            if ((new Date(format(r.created_at, 'yyyy/MM/dd').replace(/-/g, '\/').replace(/T.+/, '')).getTime() >= init.getTime())
                && (new Date(format(r.created_at, 'yyyy/MM/dd').replace(/-/g, '\/').replace(/T.+/, '')).getTime() <= end.getTime())) { return r.periodo_id === Number(values.periodo_id) && r.encuesta_id === Number(enc.id) && r.sucursal === Number(values.punto_id) }
        })
        let repos = reportes.filter(v => { return Number(v.periodo_id) === Number(values.periodo_id) && Number(v.encuesta_id) === Number(enc.id) && Number(v.sucursal) === Number(values.punto_id) })
        if (values.date_fin && values.date_init) {
            setReps(reps.filter(d => respuestas.find(r => r.reporte_id === d.id)))
        }
        else {
            setReps(repos.filter(d => respuestas.find(r => r.reporte_id === d.id)))
        }
        setPS(periodos.find(p => p.id === Number(values.periodo_id)))
        setPnt(Sucursales.find(p => p.id === Number(values.punto_id)))
        showData()
    }

    const handleEncuesta = (e) => {
        e.preventDefault()
        let enc = encuestas.find(el => el.id === Number(e.target.value))
        if (enc) {
            axios.get(API_URL + '/respuesta', { params: { encuesta_id: Number(e.target.value), periodo_id: getValues("periodo_id") } }).then(res => { setR(res.data) })
            axios.get(API_URL + '/reporte', { params: { encuesta: Number(e.target.value) } }).then(res => { setRep(res.data) })
            setE(enc)
            axios.get(API_URL + '/detractor', { params: { encuesta_id: Number(e.target.value) } }).then(res => {
                setDet(res.data)
            })
        }
    }
    const handleCity = (e) => {
        e.preventDefault()
        let sucursales = puntos.filter(s => { return Number(s.ciudad_id) === Number(e.target.value) })
        setSuc(sucursales)
    }

    //filtrar los periodos del año por defecto 
    const handleYearChange = (e) => {
        const year = parseInt(e.target.value);
        setSelectedYear(year);

        if (year) {
            const filtered = periodos.filter((p) => {
                const startYear = new Date(p.periodo_inicio).getFullYear();
                const endYear = new Date(p.periodo_fin).getFullYear();
                return startYear === year || endYear === year;
            });
            setFilteredPeriodos(filtered);
        } else {
            setFilteredPeriodos(periodos);
        }
    };


    const handlePerdiod = (e) => {
        setValue("periodo_id", Number(e.target.value))
        setEncuestas([])
        if (e && e.target.value !== null && e.target.value !== undefined) {
            let temp = joints.filter(j => { return Number(j.periodo_id) === Number(e.target.value) })
            let temp2 = Basencuestas.filter(e => { return temp.some(j => { return Number(j.encuesta_id) === Number(e.id) }) })
            setEncuestas(temp2)
        }
    }

    return (<>
        <Modal show={edit} onHide={hideEdit}>
            <Modal.Header closeButton>
                <Modal.Title>Gestión</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(gestionar)}>
                <Form.Control type="hidden" value={logData.id} {...register("client_id")} />
                <Modal.Body>
                    <Row>
                        <Col>
                            <h6>{getValues("encuestado")}</h6>
                        </Col>
                        <Col>
                            <h6>{getValues("fecha")}</h6>
                        </Col>
                    </Row>
                    <Form.Control className="mb-2" readOnly {...register("texto")} />
                    <Form.Control {...register("status")} />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="App-button-alt" onClick={hideEdit}>Cerrar</Button>
                    {!loadBuffer ? <Button type="submit" className="App-button" >Actualizar</Button>
                        : <BarLoader className="m-2" color="#4538D1" width={50} />}
                </Modal.Footer>
            </Form>
        </Modal>
        {loader ? <div className="loader">
            <ClipLoader className="m-2" color="#4538D1" width={800} />
        </div> : null}
        <div className="content-container">
            <Form onSubmit={handleSubmit(SetFilters)}>
                <Row className="mb-1">
                    {/* Selector de Año */}
                    <Col>
                        <Form.Select value={selectedYear} onChange={handleYearChange}>
                            {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </Form.Select>

                    </Col>
                    {/* Selector de Periodo */}
                    <Col>
                        <Form.Select onChange={handlePerdiod}>
                            <option value={null}>Sin Periodo Seleccionado</option>
                            {filteredPeriodos.map((enc) => (
                                <option key={enc.id} value={enc.id}>
                                    {enc.nombre_periodo}
                                </option>
                            ))}
                        </Form.Select>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Select onChange={handleEncuesta} >
                            <option value={null}>Sin Encuesta Seleccionada</option>
                            {encuestas.length > 0 ? encuestas.map(enc => { return <option value={enc.id}>{enc.nombre_encuesta}</option> }) : null}
                        </Form.Select>
                    </Col>
                    {logData.id === 49 ? <Col>
                        <Form.Select onChange={handleCity} >
                            <option value={null}>Sin Ciudad Seleccionada</option>
                            {cities.length > 0 ? cities.map(enc => { return <option value={enc.id}>{enc.nombre_ciudad}</option> }) : null}
                        </Form.Select>
                    </Col>
                        : null}
                    <Col>
                        <Form.Select {...register("punto_id")}>
                            <option value={null}>Sin Punto Seleccionado</option>
                            {userData.permiso === "admin" ?
                                Sucursales.map(enc => { return <option value={enc.id}>{enc.nombre_sucursal}</option> })
                                :
                                Sucursales.filter(el => { return userData.puntos.split(",").indexOf(el.id.toString()) > -1 })
                                    .map(enc => { return <option value={enc.id}>{enc.nombre_sucursal}</option> })
                            }
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label className='m-2 mt-1 mb-1'>Fecha inicio</Form.Label>
                        <Form.Control className='rmsc' type='date' {...register("date_init")} />
                    </Col>
                    <Col>
                        <Form.Label className='m-2 mt-1 mb-1'>Fecha fin</Form.Label>
                        <Form.Control className='rmsc' type='date' {...register("date_fin")} />
                    </Col>
                    <Col></Col>
                </Row>
                <Row className="m-1 mt-3">
                    <Button type="submit" className="App-button m-2 mt-0">Aplicar filtros <BiSearchAlt /></Button>
                </Row>
            </Form>
            {consulta ?
                <div className="mb-4">
                    <Row className="row-excel-button">
                        <h3 className="p-2" style={{ width: '50vw' }}>Gestion Detractor</h3>
                    </Row>
                    <Table className="App-Table" striped responsive="md" hover size="sm">
                        <thead>
                            <tr>
                                <th className="table-th expanded">Detractor</th>
                                <th className="table-th expanded">Fecha</th>
                                <th className="table-th expanded">Comentario</th>
                                <th className="table-th expanded">Gestionar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {detractores.filter(det => { return Reps.some(rep => { return det.periodo_id === PS.id && det.punto_id === Pnt.id && det.reporte_id === rep.id }) })
                                .map(detractor => {
                                    return <tr>
                                        <td>{detractor.encuestado}</td>
                                        <td>{detractor.fecha}</td>
                                        <td>{detractor.texto}</td>
                                        <td>{detractor.status}</td>
                                        <td className="clickable-end">
                                            <td className="clickable" onClick={() => { showEdit(detractor) }} ><BiEditAlt /></td>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </div> : null}
        </div>
    </>)

}